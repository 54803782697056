import _defineProperty from "/Users/mohamedbenkhedher/Documents/react/mspot-master/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _DEFAULT_IMAGE;

import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_INFOS, DATA_TYPE_PMRS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTS } from './dataConfig';
import { WEBAPP_MAP } from './profiles';
export function desactivateOnClickOnLi(pageKey, profile) {
  var blackListPageKey = [MOBIGEO_PAGE_KEY];
  var blackListProfile = [WEBAPP_MAP];

  if (pageKey && blackListPageKey.includes(pageKey) && profile && blackListProfile.includes(profile)) {
    return true;
  }

  return false;
}
export function isLogoFullWidth(pageKey) {
  switch (pageKey) {
    default:
      return true;
  }
}
export var EVENT_PAGE_SPEAKERS_AS_ACCORDION = false;
export var SINGLE_ITEM_DATATYPES = [DATA_TYPE_INFOS, DATA_TYPE_PMRS];
export var DEFAULT_IMAGE = (_DEFAULT_IMAGE = {}, _defineProperty(_DEFAULT_IMAGE, DATA_TYPE_PARTICIPANTS, 'files/project/misc/default-speaker-round.png'), _defineProperty(_DEFAULT_IMAGE, DATA_TYPE_CONTACTS, 'files/project/misc/default-speaker-round.png'), _DEFAULT_IMAGE);
export var CATEGORIES_CLASSIFICATIONS = {
  CATEGORY_SYSTEM: 'CATSYS',
  TOP_CATS: 'TOPCATS'
}; // CATEGORY_SYSTEM || TOP_CATS

export var CATEGORIES_CLASSIFICATION = CATEGORIES_CLASSIFICATIONS.TOP_CATS;