var defaultConf = require('./config-default');

var BO_URL = 'aria2022-data.ms-dev.mobile-spot.com';
var NODE_BACKEND_DOMAIN = '';
var NODE_WS_BAKEND_DOMAIN = '';
var LOCAL_NODE_BACKEND_DOMAIN = 'http://localhost:3082';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: "/home/legacy/generationsEvenement/aria2022/aria2022-data.ms-dev.mobile-spot.com",
  // no trailing '/'
  BO_SERVER_ALIAS: "ms-dev.mobilespot.prodleni.local",
  BO_SSH_USER: "legacy",
  // 'www'
  BO_SSH_PORT: "22",
  //'43122'
  DEPLOY_PATH: '/home/legacy/generationsEvenement/aria2022/aria2022.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-232423480-2',
  projectId: '1056757301402',
  // FCM sender id
  appId: '0EBA7-B5F0C',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    MODE: 'peering',
    // (peering or login)
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  SSO: {
    SSO_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/sso-request")
  },
  SOCIAL: {
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    FACEBOOK: {
      POST_PER_PAGE: 5
    },
    FACEBOOK2: {
      POST_PER_PAGE: 5
    },
    INSTAGRAM: {
      POST_PER_PAGE: 5
    },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social") // `${LOCAL_NODE_BACKEND_DOMAIN}/social`,

  },
  SECURED_PDF: {
    FEATURE_ENABLED: false,
    SECURED_PDF_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/secured-pdf-viewer") // `${LOCAL_NODE_BACKEND_DOMAIN}/secured-pdf-viewer`,

  }
});