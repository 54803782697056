import { EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import EventContent from './EventContent';

export default {
  key: EVENT_PAGE_KEY,
  path: '/event',
  elId: DOM_ID,
  className: 'event-page',
  component: GenericItemPage,
  childComponent: EventContent,
  relatedDataToFetch: ['exhibitor', 'exhibitors', 'store', 'places', 'speakers', 'moderators', 'presidents', 'directors', 'coordinators', 'experts', 'coautors', 'orators', 'events', 'papers', 'gmapplaces'],
};
